import React, { Fragment, useState } from "react"
import { makeStyles } from "@material-ui/core/styles/index"
import { Container, Grid, Typography, Box } from "@material-ui/core/index"
import Layout from "../components/layout"
import MainToolbar from "../components/main-toolbar"
import SEO from "../components/seo"
import LineTitle from "../components/LineTitle"
import CourseCard from "../components/Cards/CourseCard"
import backgroundImage from "../images/eduBannerFiltered.jpg"
import { injectIntl } from "gatsby-plugin-intl"
import callumArticleThumb from "../images/callumArticleThumbnail.jpg"
import callumArticle from "../images/callum-faris-face-surgery-article.jpg"
import susanArticleThumb from "../images/susanArticleThumbnail.jpg"
import susanArticle from "../images/susanArticle.jpg"
import hadeArticleThumb from "../images/hadeArticleThumbnail.jpg"
import hadeArticle from "../images/hade-vuyk-face-plastic-surgery.jpg"
import Modal from "@material-ui/core/Modal"
import flyerFrontThumb from "../images/flyerThumbnail.jpg"
import flyerBackThumb from "../images/flyerBackThumbnail.jpg"
import flyerFront from "../images/courseFlyerFront.jpg"
import flyerBack from "../images/courseFlyerBack.jpg"
import flyerFront2 from "../images/flyerFront2.jpg"
import flyerBack2 from "../images/flyerBack2.jpg"
import flyerBackThumb2 from "../images/flyerBackThumb2.jpg"
import flyerFrontThumb2 from "../images/flyerFrontThumb2.jpg"

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: "#000",
    },
  },
  bannerRight: {
    display: "flex",
    justifyContent: "flex-end",
  },
  mainContainer: {
    padding: "0 !important",
    paddingBottom: "120px",
    backgroundColor: "#202020",
  },
  container: {
    backgroundColor: "transparent",
  },
  background: {
    margin: 0,
    padding: 0,
    background: `#333 url(${backgroundImage}) 50% 0 no-repeat`,
    backgroundSize: "cover",
    [theme.breakpoints.up("sm")]: {
      minHeight: "600px",
    },
    paddingBottom: theme.spacing(5),
  },
  bannerGrid: {
    flexDirection: "row",
    ["@media (max-width:600px)"]: {
      paddingBottom: "60px",
    },
  },
  treatmentsBanner: {
    paddingTop: "85px",
    justifyContent: "flex-end",
    ["@media (max-width:600px)"]: {
      display: "none",
    },
  },
  docMobile: {
    ["@media (min-width:600px)"]: {
      display: "none",
    },
  },
  pageTitle: {
    paddingTop: "80px",
  },
  treatmentContainer: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  cardContainer: {
    display: "flex",
    flexDirection: "row",
    maxWidth: "1800px",
    alignItems: "center",
    paddingTop: "80px",
    paddingBottom: "120px",
    backgroundColor: "#202020",
  },
  listItemz: {
    paddingLeft: "14px",
    lineHeight: 1.3,
    listStyleType: "none",
    "&:before": {
      color: "#909690",
      float: "left",
      margin: "0 0 0 -1em",
      width: "1em",
      content: "'-'",
    },
  },
  cardInnerContainer: {
    paddingLeft: "80px",
    paddingRight: "80px",
  },
  categoryHeader: {
    color: "#929B91",
    padding: "40px",
    textAlign: "left",
    fontSize: "40px",
    fontWeight: "lighter",
    letterSpacing: "2px",
    ["@media (max-width:780px)"]: {
      // eslint-disable-line no-useless-computed-key
      fontSize: "2rem",
    },
  },
  tooLongtitle: {
    ["@media (max-width:600px)"]: {
      fontSize: "2.4rem",
    },
  },
  tooLongSubheader: {
    color: "white",
    textTransform: "uppercase",
    align: "left",
    marginTop: "20px",
    maxWidth: "550px",
    ["@media (max-width:600px)"]: {
      fontSize: "1.3rem",
    },
  },
  mediaCovers: {
    display: "flex",
    padding: "20px",
    overflow: "auto",
  },
  mediaThumbs: {
    marginRight: "40px",
    "&:hover": {
      opacity: 0.5,
      backgroundColor: "#929B91",
      cursor: "pointer",
    },
  },
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: "none",
  },
  article: {
    maxWidth: "100%",
    height: "auto",
  },
  categoryHeaderWrapper: {
    ["@media (max-width:780px)"]: {
      // eslint-disable-line no-useless-computed-key
      maxWidth: "350px",
    },
  },
  courseWrapper: {
    ["@media (max-width:500px)"]: {
      // eslint-disable-line no-useless-computed-key
      maxWidth: "350px",
    },
  },
}))

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

function getModalScroll() {
  return {
    overflow: "scroll",
    outline: "none",
  }
}

const EducationPage = ({ intl, location }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [modalStyle] = useState(getModalStyle)
  const [modalScroll] = useState(getModalScroll)
  const [currentMedia, setCurrentMedia] = useState(null)

  // Translate function made shorter..
  const t = (id) => intl.formatMessage({ id })
  // Used in translation of treatments
  // const createItemsForCard = (name, len) =>
  //   Array(len)
  //     .fill()
  //     .map((v, i) =>
  //       <li className={classes.listItemz}>{t(`treatments.${name}${i}`)}</li>)

  // const treatmentsCardData = [
  //   {
  //     title: t("treatments.skinItemTitle"),
  //     listItems: createItemsForCard("skinItem", 3),
  //     img: skin,
  //     iconName: 'fillersStripe',
  //     text: t("treatments.skinItemText"),
  //     backgroundColor: '#666B65',
  //   }, {
  //     title: t("treatments.eyeItemTitle"),
  //     listItems: createItemsForCard("eyeItem", 2),
  //     img: eyes,
  //     iconName: 'fillersStripe',
  //     text: t("treatments.skinItemText"),
  //     backgroundColor: '#5B5B5B',
  //   },
  // ]

  const mediaItems = [
    {
      cover: callumArticleThumb,
      content: callumArticle,
    },
    {
      cover: susanArticleThumb,
      content: susanArticle,
    },
    // {
    //   cover: hadeArticleThumb,
    //   content: hadeArticle,
    // },
  ]

  const courseCardData = [
    {
      name: "THE FACIAL RECONSTRUCTION TOOL KIT COURSE",
      title: "December 6-8, 2019 - Antwerpen University Medical Center",
      subTitle: "MD PHD",
      imgLeft: flyerFrontThumb,
      imgRight: flyerBackThumb,
      content1: flyerFront,
      content2: flyerBack,
      iconName: "education",
      text: (
        <Fragment>
          <p>
            The course is in Antwerpen University Medical Center. It is over 3
            days and the Language is English. Two delegates per specimen and
            taught by highly experienced expert facial reconstructive surgeons.
          </p>
          <p>
            It is suitable for ENT Plastic and Maxillofacial Senior Residents,
            Facial Plastic Fellows and Consultants/Attendings/Staff Surgeons.
          </p>
          <p>
            The course focused is on how to teach the delegates in a step by
            step manner how to perform common to complex facial reconstructive
            techniques and is supported by our commitment to intensive
            dissection sessions. All the tip and tricks and pearls from the
            faculty's very large experience in reconstructive surgery will be
            shared. Other reconstruction techniques We will also attempt to
            demonstrate, other reconstructive techniques if requested and time
            feasible.
          </p>
          <p>
            The format is lecture 15-20 mins then dissection for 1-2 hours. This
            is then repeated throughout the days with each new
            topic/area/surgical technique. Hotel rooms are available at a cheap
            rate of approximately 55 Euro per night for a single room at a nice
            new Hotel on the Hospital Campus (Hotel Drie Eiken{" "}
            <a href="https://www.hotel-drie-eiken.com/en/discover-hotel-drie-eiken">
              www.hotel-drie-eiken.com
            </a>
            ).
          </p>
          <p>Course price: €750, 15% Discount for EAFPS Members</p>
          <p>
            For more information or to book the course: E-mail:
            info@faceinstitute.nl Telephone: +31 (0) 85773 1993
          </p>
        </Fragment>
      ),
      id: "toolkit course",
    },
  ]

  const courseCardData2 = [
    {
      name: "THE FACIAL PLASTIC RECONSTRUCTION COURSE",
      title: "December 6-7, 2021 - Antwerpen University Medical Center",
      subTitle: "MD PHD",
      imgLeft: flyerFrontThumb2,
      imgRight: flyerBackThumb2,
      content1: flyerFront2,
      content2: flyerBack2,
      iconName: "education",
      text: (
        <Fragment>
          <p>
            The course is in Antwerpen University Medical Center. It is over 3
            days and the Language is English. Two delegates per specimen and
            taught by highly experienced expert Otolaryngology/ENT facial
            reconstructive surgeons.
          </p>
          <p>
            It is suitable for ENT, Plastic and Maxillofacial Senior Residents,
            Facial Plastic Fellows and Consultants/Attendings/Staff Surgeons.
          </p>
          <p>
            The course focused is on how to teach the delegates in a step by
            step manner how to perform common to complex facial reconstructive
            techniques and is supported by our commitment to intensive
            dissection sessions. All the tips, tricks and pearls from the
            faculty's very large experience in reconstructive surgery will be
            shared. Other reconstruction techniques We will also attempt to
            demonstrate, other reconstructive techniques if requested and time
            feasible.
          </p>
          <p>
            The format is lecture 15-20 mins then dissection for 1-2 hours. This
            is then repeated throughout the days with each new
            topic/area/surgical technique. Hotel rooms are available at a cheap
            rate of approximately 58 Euro per night for a single room at a nice
            new Hotel on the Hospital Campus (Hotel Drie Eiken{" "}
            <a href="https://www.hotel-drie-eiken.com/en/discover-hotel-drie-eiken">
              www.hotel-drie-eiken.com
            </a>
            ).
          </p>
          <p>Course price: €950, 15% Discount for EAFPS Members</p>
          <p>
            For more information or to book the course: E-mail:
            callum.faris@uza.be
          </p>
        </Fragment>
      ),
      id: "toolkit course",
    },
  ]

  const handleOpen = (content) => {
    setOpen(true)
    setCurrentMedia(content)
  }
  const handleClose = () => {
    setOpen(false)
    // console.log(currentMedia)
  }
  const handleFlyerClick = (content1, content2) => {
    setOpen(true)
    setCurrentMedia(content1 ? content1 : content2)
  }

  return (
    <Layout>
      <SEO
        lang={intl.formatMessage({ id: "global.lang" })}
        title={intl.formatMessage({ id: "media.SEOtitle" })}
        description={intl.formatMessage({ id: "media.SEO" })}
        path={location.pathname}
      />
      <MainToolbar />
      <Container maxWidth={"xl"} className={classes.mainContainer}>
        <Container maxWidth={"xl"} className={classes.background}>
          <Grid container spacing={0} className={classes.bannerGrid}>
            <Grid item xs={12} sm={6}>
              <Container>
                <LineTitle
                  header={t("education.header")}
                  subheader={t("education.subheader")}
                  className={classes.pageTitle}
                  titleStyle={classes.tooLongtitle}
                  subHeaderStyle={classes.tooLongSubheader}
                />
              </Container>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.bannerRight}>
              {/* <img src={treatmentsBanner} alt="Face silhouette" className={classes.treatmentsBanner}></img> */}
              {/* <img src={manDocMobile}  alt="Doctor" className={classes.docMobile}></img> */}
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth={"xl"} className={classes.cardContainer}>
          <Container maxWidth={"xl"} className={classes.treatmentContainer}>
            <h2 className={classes.categoryHeader}>MEDIA</h2>
            <div className={classes.mediaCovers}>
              {mediaItems.map((item) => (
                <div
                  className={classes.mediaThumbs}
                  onClick={() => handleOpen(item.content)}
                >
                  <img src={item.cover}></img>
                </div>
              ))}
              <Modal
                aria-labelledby="Article"
                open={open}
                onClose={handleClose}
                style={modalScroll}
              >
                <div className={classes.paper} style={modalStyle}>
                  <img src={currentMedia} className={classes.article}></img>
                </div>
              </Modal>
            </div>
            {/* <h2 className={classes.categoryHeader}>UPCOMING COURSES</h2> */}
            <Box className={classes.categoryHeaderWrapper}>
              <Typography
                component="h2"
                variant="h4"
                className={classes.categoryHeader}
              >
                UPCOMING COURSES
              </Typography>
            </Box>
            {courseCardData2.map((card) => (
              <Box className={classes.courseWrapper}>
                <CourseCard
                  name={card.name}
                  title={card.title}
                  iconName={card.iconName}
                  imgLeft={card.imgLeft}
                  imgRight={card.imgRight}
                  key={card.name}
                  id={card.id}
                  contentClick1={() => handleFlyerClick(card.content1)}
                  contentClick2={() => handleFlyerClick(card.content2)}
                >
                  {card.text}
                </CourseCard>
              </Box>
            ))}
          </Container>
        </Container>
      </Container>
    </Layout>
  )
}

export default injectIntl(EducationPage)
